<template>
  <el-drawer title="大富翁详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
    :modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
    <div class="h-full flex flex-dc" v-if="isDrawer">
      <div class="drawer_title pall-30 flex flex-jb flex-n">
        <div class="fs-18 fw-bold">大富翁详情</div>
        <i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
      </div>
      <div class="flex-1 overflow-scroll-y">
        <t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
          class="drawer_form">
          <!-- 平台自定义插槽 -->
          <template #json>
            <div class="pos-s t-0 bgc-fff zi-10">
              <div class="pb-20 pl-40 flex flex-ac flex-jb">
                <div class="fs-14 fw-bold flex-1">
                  <span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
                  <span>系列商品</span>
                </div>
                <div class="flex flex-ac">
                  <div class="mr-30">数量：{{formOpts.formData.json.length}}</div>
                  <el-button type="primary" @click="addDomain">新增</el-button>
                </div>
              </div>
            </div>
            <div class="pl-40">
              <el-row :gutter="10">
                <el-col :span="1">预售</el-col>
                <el-col :span="1"><span class="fc-ff7161">*</span>排序</el-col>
                <el-col :span="3"><span class="fc-ff7161">*</span>道具名称</el-col>
                <el-col :span="2"><span class="fc-ff7161">*</span>道具等级</el-col>
                <el-col :span="2" v-if="!testFlag"><span class="fc-ff7161">*</span>累计消费</el-col>
                <el-col :span="2"><span class="fc-ff7161">*</span>道具类型</el-col>
                <el-col :span="2"><span class="fc-ff7161">*</span>赠送数量</el-col>
                <el-col :span="2">有效期</el-col>
                <el-col :span="2">优惠券类型</el-col>
                <el-col :span="2">优惠券金额</el-col>
                <el-col :span="2">优惠券门槛</el-col>
                <el-col :span="1">图片</el-col>
                <!-- <el-col :span="2" v-if="formOpts.formData.json.length > 1">操作</el-col> -->
              </el-row>
              <el-row v-for="(domains,index) in formOpts.formData.json" :key="index" :gutter="20" type="flex"
                align="center" class="mt-20"
                :class="(index == 4 || index == 9 || index == 15 || index == 19 || index == 22 || index == 28 || index == 34)?'bgc-ebf4fe':''">
                <el-col :span="1" class="flex flex-ac">
                  <el-checkbox v-model="domains.isPresell" :true-label="1" :false-label="2"></el-checkbox>
                </el-col>
                <el-col :span="1" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.sort'" class="flex flex-ac"
                    :rules="{required: true, message: '排序不能为空', trigger: 'blur'}">
                    <span class="fw-bold"
                      :class="(index == 4 || index == 9 || index == 15 || index == 19 || index == 22 || index == 28 || index == 34)?'fc-3b7cff':''">
                      {{domains.sort}}
                    </span>
                    <!-- <el-input v-model="domains.sort" type="number" clearable disabled></el-input> -->
                  </el-form-item>
                </el-col>
                <el-col :span="3" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.propName'"
                    :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}" class="flex flex-ac">
                    <el-input v-model="domains.propName" type="text" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.propLevel'"
                    :rules="{required: true, message: '请选择道具等级', trigger: 'change'}" class="flex flex-ac">
                    <el-select v-model="domains.propLevel" placeholder="请选择">
                      <el-option v-for="item in gradeConsumes" :key="item.value" :label="item.key" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac" v-if="!testFlag">
                  <el-form-item :prop="'json.' + index + '.actyLevelId'"
                    :rules="{required: true, message: '请选择累计消费', trigger: 'change'}" class="flex flex-ac">
                    <el-select v-model="domains.actyLevelId" placeholder="请选择">
                      <el-option v-for="item in actyLevelList" :key="item.id" :label="item.levelName" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.propType'"
                    :rules="{required: true, message: '请选择道具类型', trigger: 'change'}" class="flex flex-ac">
                    <el-select v-model="domains.propType" clearable placeholder="请选择">
                      <el-option v-for="item in propTypes" :key="item.value" :label="item.key" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.num'"
                    :rules="{required: true, message: '赠送数量不能为空', trigger: 'blur'}" class="flex flex-ac">
                    <el-input v-model="domains.num" type="number" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.validDay'"
                    :rules="(domains.propType != 1 && domains.propType != 6 && domains.propType)?{required: true, message: '有效天数不能为空', trigger: 'blur'}:''">
                    <el-input v-model="domains.validDay" type="number" placeholder="选择有效天数" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.couponsType'"
                    :rules="domains.propType == 4?{required: true, message: '请选择优惠卷类型', trigger: 'change'}:''">
                    <el-select v-model="domains.couponsType" clearable placeholder="请选择优惠券类型">
                      <el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.money'"
                    :rules="domains.propType == 4?{required: true, message: '优惠券金额不能为空', trigger: 'blur'}:''">
                    <el-input v-model="domains.money" type="number" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2" class="flex flex-ac">
                  <el-form-item :prop="'json.' + index + '.doorSill'"
                    :rules="domains.propType == 4?{required: true, message: '优惠券门槛不能为空', trigger: 'blur'}:''">
                    <el-input v-model="domains.doorSill" type="number" clearable></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1">
                  <el-form-item :prop="'json.' + index + '.propImg'" class="flex flex-ac">
                    <upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
                      iconName="el-icon-upload" iconSize="16" v-model="domains.propImg"></upload-file>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="2" class="flex flex-ac" v-if="formOpts.formData.json.length > 1">
                  <el-button type="primary" plain @click.prevent="removeDomain(index)">删除</el-button>
                </el-col> -->
              </el-row>
            </div>
          </template>
          <!-- 上传图片 -->
          <template #uploadFile="data">
            <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
              iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
          </template>
        </t-simple-form>
      </div>
      <div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
        <el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
        <el-button type="primary" plain @click="handleClose">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'rewardDetails',
  components: {
    "TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'propTypes': state => state.dictList ? state.dictList.prop_type : [],  //道具类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      goodId: '',//详情id
      isDrawer: false, //详情弹框
      butLoading: false,
      formOpts: {
        labelPosition: 'top',
        ref: null,
        formData: {
          diceName: '',	//活动名称
          price: '',	//原价
          soldPrice: '',	//售出价格
          shelves: '',//是否上下架
          totalPage: '',//总页码
          money: '',  // 回收优惠金面额
          recycleBlance: '', //回收余额
          dateDay: '',  //优惠金有效期
          id: '',  //系列id
          note: '',  //玩法说明
          diceImg: '',
          sort: '',//排序
          json: [{
            isPresell: 2,//是否预售  1预售  2现货
            sort: 1, //排序
            propName: '', //道具名称
            propLevel: '',//道具等级
            propType: '', //道具类型
            validDay: '',//有效期
            money: '',//优惠券金额
            doorSill: '',//优惠券门槛
            couponsType: '',//优惠券类型
            num: 1,//赠送数量
            actyLevelId: '', //	累计消费类型
            propImg: '', //道具图片
          }], //商品信息
        },
        fieldList: [
          { label: '活动名称', value: 'diceName', comp: 'el-input' },
          { label: '发货日期', value: 'deliverDate', type: 'date', comp: 'el-date-picker', bind: { format: "yyyy-MM-dd", valueFormat: "yyyy-MM-dd" } },
          { label: '上下架售罄', value: 'shelves', type: 'select-arr', comp: 'el-select', list: 'shelves', arrLabel: 'key', arrKey: 'value' },
          { label: '总页码', value: 'totalPage', type: 'number', comp: 'el-input' },
          { label: '原价', value: 'price', type: 'number', comp: 'el-input', },
          { label: '售出价格', value: 'soldPrice', type: 'number', comp: 'el-input' },
          { label: '回收优惠金额', value: 'money', type: 'number', comp: 'el-input', isHideItem: () => { return !this.testFlag } },
          { label: '优惠金有效期	', value: 'dateDay', type: 'number', comp: 'el-input', isHideItem: () => { return !this.testFlag } },
          { label: '回收虚拟币	', value: 'recycleBlance', type: 'number', comp: 'el-input', isHideItem: () => { return !this.testFlag } },
          { label: '排序', value: 'sort', type: 'number', comp: 'el-input' },
          { label: '玩法说明', value: 'note', type: 'textarea', comp: 'el-input', className: 't-form-block' },
          { label: '活动图片', value: 'diceImg', slotName: 'uploadFile' },
          { label: '', slotName: 'json', className: 't-form-block' },
        ],
        rules: {
          diceName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
          shelves: [{ required: true, message: '请至少选择一个状态', trigger: 'change' }],
          goodSeriesType: [{ required: true, message: '请至少选择一个分类', trigger: 'change' }],
          deliverDate: [{ required: true, message: '请选择发货日期', trigger: 'blur' }],
          price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
          soldPrice: [{ required: true, message: '请输入现价', trigger: 'blur' }],
          totalPage: [{ required: true, message: '请输入总套数', trigger: 'blur' }],
          showPage: [{ required: true, message: '请输入展示的套数', trigger: 'blur' }],
          money: [{ required: true, message: '请输入回收优惠金额度', trigger: 'blur' }],
          dateDay: [{ required: true, message: '请输入优惠金有效天数', trigger: 'blur' }],
          recycleBlance: [{ required: true, message: '请输入回收鸭币数量', trigger: 'blur' }],
        },
        //按钮
        operatorList: [],
        // 相关列表
        listTypeInfo: {
          shelves: this.$store.state.menu.dictList.shelves
        }
      },
      testFlag: false, //测试
      gradeConsumes: [{
        value: 0,
        key: '稀有'
      }, {
        value: 1,
        key: '欧皇'
      }, {
        value: 2,
        key: '普通'
      }, {
        value: 3,
        key: '必得'
      }],
      actyLevelList: [],//累积消费类型
    }
  },
  created () {
    //测试人员
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.name == '测试') {
      this.testFlag = true
    }
  },
  mounted () { },
  methods: {
    // 初始化
    init (id) {
      if (id) {
        this.goodId = id
        this.getDetails(id)
      } else {
        let jsonList = []
        for (let i = 0; i < 35; i++) {
          let propLevel = ''
          if (i != 4 && i != 9 && i != 15 && i != 19 && i != 22 && i != 28 && i != 34) {
            propLevel = 2
          }
          let jsonArr = {
            isPresell: 2,//是否预售  1预售  2现货
            sort: i + 1, //排序
            propName: '', //道具名称
            propLevel: propLevel,//道具等级
            propType: '', //道具类型
            validDay: '',//有效期
            money: '',//优惠券金额
            doorSill: '',//优惠券门槛
            couponsType: '',//优惠券类型
            num: 1,//赠送数量
            actyLevelId: '', //	累计消费类型
            propImg: '', //道具图片
          }
          jsonList.push(jsonArr)
        }
        this.formOpts.formData.json = jsonList
      }
      this.findActyLevelConsumeList()
      this.isDrawer = true
    },
    //关闭弹框
    handleClose () {
      this.isDrawer = false
      this.resetForm()
    },
    //获取详情数据
    getDetails (id) {
      this.$http.get(`/diceActy/findDiceActyById?id=${id}`).then(({ data: res }) => {
        let goodData = res.data.acty
        goodData.json = res.data.propList
        this.formOpts.formData = goodData
      })
    },
    //获取活动累计消费
    findActyLevelConsumeList () {
      this.$http.get('/actyLevelConsume/findActyLevelConsumeList', {
        params: {
          currentPage: 1,
          pageSize: 20,
          actyType: 1,
        }
      }).then(({ data: result }) => {
        this.actyLevelList = result.data.list
      })
    },
    // 触发事件
    handleEvent (type, val) {
      switch (type) {
        case 'checkbox':
          console.log(val, type)
          break
        case 'showPage':
          if (!this.goodId) {
            this.formOpts.formData.totalPage = val
          }
          break
      }
    },
    // 新增商品
    addDomain () {
      let json = this.formOpts.formData.json,
        sort = json[json.length - 1].sort + 1
      this.formOpts.formData.json.push({
        isPresell: 2,//是否预售  1预售  2现货
        sort: sort, //排序
        propName: '', //道具名称
        propLevel: '',//道具等级
        propType: '', //道具类型
        validDay: '',//有效期
        money: '',//优惠券金额
        doorSill: '',//优惠券门槛
        couponsType: '',//优惠券类型
        num: 1,//赠送数量
        actyLevelId: '', //	累计消费类型
        propImg: '', //道具图片
      })
    },
    removeDomain (index) {
      this.formOpts.formData.json.splice(index, 1)
    },
    // 提交form表单
    submitForm () {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.butLoading = true
          console.log(77, this.formOpts.formData)
          if (!valid) return
          console.log('最终数据', this.formOpts.formData)
          let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
          goodData.json = JSON.stringify(goodData.json)
          let apiUrl = ''
          if (goodData.id) {    //修改
            apiUrl = '/diceActy/editDiceActy'
          } else {   //新增
            apiUrl = '/diceActy/addDiceActy'
          }
          this.$http.post(apiUrl, goodData).then(res => {
            this.$message({
              message: goodData.id ? '修改成功' : '新增成功',
              type: 'success'
            })
            this.butLoading = false
            this.$emit('refresh')
            this.handleClose()
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    // 重置form表单
    resetForm () {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data.call(this).formOpts.formData
      )
      this.$nextTick(() => {
        this.formOpts.ref.clearValidate()
      })
    },
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  word-break: break-all;
}
</style>